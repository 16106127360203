// extracted by mini-css-extract-plugin
export var portfolioItem = "portfolio-item-module--portfolioItem--VCtcA";
export var portfolioItem__category = "portfolio-item-module--portfolioItem__category--ngcQh";
export var portfolioItem__content = "portfolio-item-module--portfolioItem__content--ZuQfG";
export var portfolioItem__description = "portfolio-item-module--portfolioItem__description--TofMv";
export var portfolioItem__details = "portfolio-item-module--portfolioItem__details--maWmS";
export var portfolioItem__details___design = "portfolio-item-module--portfolioItem__details___design--4LpkG";
export var portfolioItem__details___game = "portfolio-item-module--portfolioItem__details___game--8cLxI";
export var portfolioItem__details___other = "portfolio-item-module--portfolioItem__details___other--swZDY";
export var portfolioItem__details___website = "portfolio-item-module--portfolioItem__details___website--pr9LW";
export var portfolioItem__thumbnail = "portfolio-item-module--portfolioItem__thumbnail--BO0Vp";
export var portfolioItem__thumbnail___design = "portfolio-item-module--portfolioItem__thumbnail___design--ckqoR";
export var portfolioItem__thumbnail___game = "portfolio-item-module--portfolioItem__thumbnail___game--kzT0p";
export var portfolioItem__thumbnail___other = "portfolio-item-module--portfolioItem__thumbnail___other--fjAfy";
export var portfolioItem__thumbnail___website = "portfolio-item-module--portfolioItem__thumbnail___website--j1m1A";