import * as React from 'react';

import { badge } from './badge.module.scss';

const Badge = ({ children, color }: any) => (
    <div className={badge}>
        {children}
    </div>
);

export default Badge;