import { useStaticQuery, graphql } from "gatsby";

export const useSettings = () => {
    const { directus }: Queries.DirectusSettingsQuery = useStaticQuery(graphql`
        query DirectusSettings {
            directus {
                settings {
                    name
                    brand_colour
                    introduction
                    profile_picture {
                        id
                        imageFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    `);

    return directus.settings;
}