import { useStaticQuery, graphql } from "gatsby";

export const useCategories = () => {
    const { directus }: Queries.DirectusCategoriesQuery = useStaticQuery(graphql`
        query DirectusCategories {
            directus {
                categories {
                    id
                    sort
                    slug
                    name
                }
            }
        }
    `);

    return directus.categories;
}