import { useStaticQuery, graphql } from "gatsby";

export const usePortfolio = () => {
    const { directus }: Queries.DirectusPortfolioQuery = useStaticQuery(graphql`
        query DirectusPortfolio {
            directus {
                portfolio {
                    id
                    sort
                    slug
                    name
                    content
                    image {
                        id
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(width: 400)
                            }
                        }
                    }
                    categories {
                        categories_id {
                            id
                            slug
                            name
                            colour
                            background_colour
                        }
                    }
                    technologies
                }
            }
        }
    `);

    return directus.portfolio;
}