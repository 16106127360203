import * as React from 'react';

import { button } from './button.module.scss';

const Button = ({ color, text, onClick }: any) => (
    <button onClick={onClick} className={button}>
        {text}
    </button>
);

export default Button;