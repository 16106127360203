import React, { useState, useMemo } from 'react';

import SectionTitle from 'components/section-title/section-title';
import PortfolioItem from 'components/portfolio-item/portfolio-item';
import { useCategories } from 'hooks/categories.hook';
import { usePortfolio } from 'hooks/portfolio.hook';
import {
    portfolio,
    portfolio__filter,
    portfolio__filterCategory,
    portfolio__items,
} from './portfolio.module.scss';

const Portfolio = (): JSX.Element => {
    const [filter, setFilter] = useState<string>('all');
    const items = usePortfolio();
    const categories = useCategories();

    const filterItems = useMemo(() => {
        if (filter === 'all') return items;
        return items.filter((item) => item.categories.categories_id[1].slug === filter);
    }, [filter]);

    const Filter = () => (
        <div className={portfolio__filter}>
            {categories.map(({ id, slug, name }): JSX.Element => {
                return (
                    <div
                        key={`portfolio-category-${id}`}
                        className={portfolio__filterCategory}
                        onClick={() => setFilter(slug)}
                    >
                        {name}
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={portfolio}>
            <SectionTitle title="Recent work" align="center" />
            <Filter />
            <div className={portfolio__items}>
                {filterItems.map((item) => (
                    <PortfolioItem key={`portfolio-item-${item.slug}`} {...item} />
                ))}
            </div>
        </div>
    );
};

export default Portfolio;