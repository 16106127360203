import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from 'components/button/button';
import Markdown from 'components/markdown/markdown';
import { useSettings } from 'hooks/settings.hook';
import { hero, hero__avatar } from './hero.module.scss';

const Hero = (): JSX.Element => {
    const { name, introduction, profile_picture } = useSettings();
    const image = getImage(profile_picture.imageFile);

    return (
        <div className={hero}>
            <div className={hero__avatar}>
                <GatsbyImage
                    image={image}
                    alt={`Avatar of ${name}, web developer in Glasgow.`}
                />
            </div>
            <h1>{name}</h1>
            <Markdown source={introduction} container />
            <Button color="primary" text="View work" />
        </div>
    );
}

export default Hero;