import * as React from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'react-flexbox-grid';

import CallToAction from 'components/call-to-action/call-to-action';
import Hero from 'components/hero/hero';
import Portfolio from 'components/portfolio/portfolio';
import Section from 'components/section/section';

const HomePage = () => (
    <>
        <Grid>
            <Section colour="white" full>
                <Hero />
            </Section>
            <Section id="portfolio">
                <Portfolio />
            </Section>
        </Grid>
        <Section style={{ margin: 0, padding: 0 }}>
            <CallToAction slug="download-cv" />
        </Section>
    </>
);

export const Head = ({ data }: any) => (
    <>
        <title>{data.directus.pages.title}</title>
        <meta name="description" content={data.directus.pages.description} />
        <meta name="robots" content={data.directus.robots} />
    </>
);

export const query = graphql`
    query {
        directus {
            pages(filter: { slug: { _eq: "home" }}) {
                title
                description
                robots
            }
        }
    }
`;

export default HomePage;