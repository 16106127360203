import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import Markdown from 'components/markdown/markdown';
import Badge from 'components/badge/badge';
import { Props } from './portfolio-item.interface';
import {
    portfolioItem,
    portfolioItem__content,
    portfolioItem__category,
    portfolioItem__description,
    portfolioItem__details,
    portfolioItem__thumbnail,
    portfolioItem__thumbnailImage,
} from './portfolio-item.module.scss';

const PortfolioItem = ({ id, slug, name, content, technologies, categories, image }: any): JSX.Element => {
    return (
        <Link to={`/${slug}/`} className={portfolioItem}>
            <div className={portfolioItem__content}>
                <div className={portfolioItem__category}>
                    <Badge color={categories[1].categories_id.slug}>{categories[1].categories_id.name}</Badge>
                </div>
                <h3>{name}</h3>
                <Markdown className={portfolioItem__description} source={content.substring(0, 150)} />
                <div className={portfolioItem__details}>
                    Details <FontAwesomeIcon icon={faArrowRight} />
                </div>
            </div>
            <div className={portfolioItem__thumbnail}>
                <GatsbyImage
                    image={getImage(image.imageFile)}
                    alt={`Thumbnail image of ${name}.`}
                    className={portfolioItem__thumbnailImage}
                />
            </div>
        </Link>
    );
}

export default PortfolioItem;